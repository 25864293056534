/**
 * Main JS
 */
(function($) {

  let global_markers = [];
  let global_infowindows = [];

  function set_body_padding () {
    let hh = $('header.header').outerHeight();
    $('body').css('padding-top', hh);
  }//end set_body_padding


  // Close map infowindow function
  function closeInfowindows() {
    for (var x = 0; x < global_infowindows.length; x++) {
      global_infowindows[x].close();
    }
  }

  $(function() {


    //Set the body padding top on window resize to accommodate fixed header
    set_body_padding();
    $(window).resize(function () {
      set_body_padding();
    });


    //Mobile nav menu click
    $(document).on('click', 'div.hamburger', function () {

      let $this = $(this),
          navbar = $('nav.navbar');

      $this.toggleClass('is-active');
      navbar.find('div.navbar-collapse').toggleClass('collapse');

      if ($this.hasClass('is-active')) {
        $this.attr('aria-expanded', 'true').removeClass('closed');
      } else {
        $this.attr('aria-expanded', 'false').addClass('closed');
      }
    });


    //Touch device menu clicks
    $('html.touchevents ul > li.menu-item-has-children > a').click(function(e){

      var $this = $(this),
          li = $this.parent('li');

      if (!li.hasClass('click-active')) {
        li.addClass('click-active');
        e.preventDefault();
        return false;
      }

    });


    //If we're on a touch device then remove all the nav click classes
    $(document).on('click', 'body > main, body > footer#footer', function () {
      if ($('html').hasClass('touchevents')) {
        $('ul li.menu-item-has-children').removeClass('click-active');
      }
    });


    $('.magnific-gallery').each(function () {
      $(this).magnificPopup({
        delegate: 'a',
        type: 'image',
        preloader: true,
        gallery: {
          enabled: true
        },
        removalDelay: 500, //delay removal by X to allow out-animation
        callbacks: {
          beforeOpen: function () {
            // just a hack that adds mfp-anim class to markup
            this.st.image.markup = this.st.image.markup.replace('mfp-figure', 'mfp-figure mfp-with-anim');
            this.st.mainClass = this.st.el.attr('data-effect');
          }
        },
        closeOnContentClick: true,
        midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
      });
    });//end image modal


    $('.gallery-carousel').owlCarousel({
      items: 3,
      dots: true,
      nav: false,
      loop: false,
      responsiveClass: true,
      margin: 0,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        }
      }
    });


    if ($('.isotope-wrapper').length) {

      //Set height for each item before iso init:
      let items = $('div.lib-card-outer'),
          height = 0;
      items.each(function(){
        let $this = $(this);
        if (parseInt($this.height()) > height) {
          height = parseInt($this.height());
        }
      });

      items.height(height);

      $('.isotope-wrapper').isotope({
        itemSelector: '.isotope-item',
        layoutMode: 'fitRows',
        animationEngine: 'css',
        initLayout: false,
        transitionDuration: '0.1s'
      });

      //Filters select onChange
      $('select#tags-filter').on('change', function () {
          var val = ($(this).val() === '*') ? '*' : '.'+$(this).val();
          $('.isotope-wrapper').isotope({filter: val});
      });
    }//end if isowrapper


    /**
     * initMap
     *
     * Renders a Google Map onto the selected jQuery element
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $el The jQuery element.
     * @return  object The map instance.
     */
    function initMap($el) {

      // Find marker elements within map.
      var $markers = $el.find('.marker');

      // Create generic map.
      var mapArgs = {
        zoom: parseInt($el.data('zoom')) || 16,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        mapTypeControl: false
      };

      var args = {
        zoom: $el.data('zoom') || 16,
        center: new google.maps.LatLng(0, 0),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        mapTypeControl: false,
        styles: [
          {
            "featureType": "administrative",
            "elementType": "labels.text.fill",
            "stylers": [
              {
                "color": "#034869"
              }
            ]
          },
          {
            "featureType": "administrative.neighborhood",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
              {
                "color": "#e3e3e3"
              }
            ]
          },
          {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
              {
                "saturation": -100
              },
              {
                "lightness": 45
              }
            ]
          },
          {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
              {
                "visibility": "off"
              }
            ]
          },
          {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
              {
                "color": "#4677b5"
              },
              {
                "visibility": "on"
              }
            ]
          }
        ]
      };
      var map = new google.maps.Map($el[0], args);

      // Add markers.
      map.markers = [];
      $markers.each(function () {
        initMarker($(this), map);
      });

      // Center map based on markers.
      centerMap(map);

      // Toggles between infowindows
      for (var i = 0; i < global_markers.length; i++) {
        // Keep value of 'i' in event creation
        (function (i) {
          google.maps.event.addListener(global_markers[i], 'click', function () {
            closeInfowindows();
            global_infowindows[i].open(map, global_markers[i]);
          });
        }(i));
      }

      // Return map instance.
      return map;
    }

    /**
     * initMarker
     *
     * Creates a marker for the given jQuery element and map.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   jQuery $el The jQuery element.
     * @param   object The map instance.
     * @return  object The marker instance.
     */
    function initMarker($marker, map) {

      // Get position from marker.
      var lat = $marker.data('lat');
      var lng = $marker.data('lng');

      var latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      };

      // Create marker instance.
      var marker = new google.maps.Marker({
        position: latLng,
        map: map,
        icon: {
          url: $marker.data('pin-icon'),
          scaledSize: new google.maps.Size(50, 50)
        }
      });

      // Append to reference for later use.
      map.markers.push(marker);
      global_markers.push(marker);

      // If marker contains HTML, add it to an infoWindow.
      if ($marker.html()) {

        // Create info window.
        var infowindow = new google.maps.InfoWindow({
          content: $marker.html()
        });

        global_infowindows.push(infowindow);

        // Show info window when marker is clicked.
        /*google.maps.event.addListener(marker, 'click', function () {
          infowindow.open(map, marker);
        });*/
      }
    }

    /**
     * centerMap
     *
     * Centers the map showing all markers in view.
     *
     * @date    22/10/19
     * @since   5.8.6
     *
     * @param   object The map instance.
     * @return  void
     */
    function centerMap(map) {

      // Create map boundaries from all map markers.
      var bounds = new google.maps.LatLngBounds();
      map.markers.forEach(function (marker) {
        bounds.extend({
          lat: marker.position.lat(),
          lng: marker.position.lng()
        });
      });

      // Case: Single marker.
      if (map.markers.length == 1) {
        map.setCenter(bounds.getCenter());

        // Case: Multiple markers.
      } else {
        map.fitBounds(bounds);
      }
    }

    $('.theme-map').each(function () {
      var map = initMap($(this));
    });


    if ($('select#locations-select').length) {

      let map_select = $('select#locations-select');
      map_select.change(function () {
        var $this = $(this);
        google.maps.event.trigger(global_markers[$this.val()], 'click');
      });

      if (map_select.hasClass('has-selected')) {
        map_select.trigger('change');
      }
    }


  });

})(jQuery); // Fully reference jQuery after this point.
